import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section, Icon, Image, Link } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { MdDoneAll } from "react-icons/md";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Главная | Добро пожаловать в Greenwood Fairways
			</title>
			<meta name={"description"} content={"Где каждый удар рассказывает свою историю"} />
			<meta property={"og:title"} content={"Главная | Добро пожаловать в Greenwood Fairways"} />
			<meta property={"og:description"} content={"Где каждый удар рассказывает свою историю"} />
			<meta property={"og:image"} content={"https://zuniqa.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://zuniqa.com/img/golf-club-5093516.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://zuniqa.com/img/golf-club-5093516.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://zuniqa.com/img/golf-club-5093516.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://zuniqa.com/img/golf-club-5093516.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://zuniqa.com/img/golf-club-5093516.png"} />
			<meta name={"msapplication-TileImage"} content={"https://zuniqa.com/img/golf-club-5093516.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="140px 0 210px" sm-padding="40px 0 40px 0" background="linear-gradient(0deg,rgba(0, 0, 0, 0.6) 0%,rgba(0, 0, 0, 0.6) 100%),#000000 url(https://zuniqa.com/img/1.jpg) center/cover" min-height="60vh">
			<Override slot="SectionContent" sm-align-items="center" />
			<Box max-width="560px" padding="50px 80px 80px 50px" background="--color-light" color="--dark">
				<Text
					as="h4"
					font="--base"
					color="--grey"
					letter-spacing="1px"
					text-transform="uppercase"
					margin="6px 0"
				>
					Добро пожаловать
				</Text>
				<Text as="h2" font="--headline2" margin="0 0 12px 0">
				Greenwood Fairways
				</Text>
				<Text font="--base">
				Добро пожаловать в Greenwood Fairways, сердце яркого сообщества любителей гольфа. Если вы оттачиваете свое мастерство или просто пришли за радостью игры, мы предлагаем уникальное сочетание традиций и очарования, расположенное в живописном месте. Наше поле создано для того, чтобы бросать вызов и вдохновлять, независимо от уровня вашего мастерства. Присоединяйтесь к нам, где каждое посещение похоже на возвращение домой.

				</Text>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				lg-margin="0px 0px 20px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 0px"
				lg-width="100%"
				md-margin="0px 0px 20px 0px"
				sm-margin="0px 0px 30px 0px"
			>
				<Text
					margin="0px 0px 20px 0px"
					color="--dark"
					font="normal 700 48px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					padding="0px 16px 0px 0px"
				>
					О нас
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL1"
					font="--lead"
					lg-text-align="center"
					display="flex"
					align-items="flex-start"
				>
					В Greenwood Fairways мы гордимся тем, что предлагаем непревзойденный опыт игры в гольф. Наше тщательно ухоженное 18-луночное поле органично вписывается в природный ландшафт, обеспечивая одновременно красоту и сложность. За пределами фервеев наш клубный дом служит уютным пристанищем, где члены клуба могут расслабиться и поделиться историями о своих гольф-подвигах. Наша цель - создать гостеприимную атмосферу, в которой каждый гость чувствует свою значимость, а каждая игра становится незабываемой.
				</Text>
			</Box>
		</Section>
		<Section color="--dark">
			<Text as="h2" font="--headline2" md-font="--headline2" margin="20px 0 0 0">
			Почему стоит выбрать Greenwood Fairways?
			</Text>
			<Box
				margin="36px 0 0 0"
				padding="0 0 0 54px"
				sm-padding="54px 0 0 0"
				position="relative"
				sm-margin="24px 0 0 0"
			>
				<Icon
					position="absolute"
					top="8px"
					left="0"
					size="36px"
					color="--secondary"
					category="md"
					icon={MdDoneAll}
				/>
				<Text as="h3" font="--headline3" margin="10px 0">
				Общественный дух
				</Text>
				<Text as="p" font="--lead" margin="10px 0" color="--greyD2">
				Мы не просто клуб, мы - семья. Участвуйте в оживленных турнирах, светских мероприятиях и заводите друзей на всю жизнь.
				</Text>
			</Box>
			<Box
				margin="36px 0 0 0"
				padding="0 0 0 54px"
				sm-padding="54px 0 0 0"
				position="relative"
				sm-margin="24px 0 0 0"
			>
				<Icon
					position="absolute"
					top="8px"
					left="0"
					size="36px"
					color="--secondary"
					category="md"
					icon={MdDoneAll}
				/>
				<Text as="h3" font="--headline3" margin="10px 0">
				Исключительный дизайн поля
				</Text>
				<Text as="p" font="--lead" margin="10px 0" color="--greyD2">
				Наше поле предлагает разнообразные рельефы и стратегические задачи, которые подойдут как новичкам, так и опытным игрокам.
				</Text>
			</Box>
			<Box
				margin="36px 0 0 0"
				padding="0 0 0 54px"
				sm-padding="54px 0 0 0"
				position="relative"
				sm-margin="24px 0 0 0"
			>
				<Icon
					position="absolute"
					top="8px"
					left="0"
					size="36px"
					color="--secondary"
					category="md"
					icon={MdDoneAll}
				/>
				<Text as="h3" font="--headline3" margin="10px 0">
				Индивидуальные тренировки
				</Text>
				<Text as="p" font="--lead" margin="10px 0" color="--greyD2">
				повысьте свой уровень игры с нашими опытными инструкторами, которые предлагают индивидуальные уроки, учитывающие ваши цели.
				</Text>
			</Box>
			<Box
				margin="36px 0 0 0"
				padding="0 0 0 54px"
				sm-padding="54px 0 0 0"
				position="relative"
				sm-margin="24px 0 0 0"
			>
				<Icon
					position="absolute"
					top="8px"
					left="0"
					size="36px"
					color="--secondary"
					category="md"
					icon={MdDoneAll}
				/>
				<Text as="h3" font="--headline3" margin="10px 0">
				Экосознательное обслуживание
				</Text>
				<Text as="p" font="--lead" margin="10px 0" color="--greyD2">
				Мы стремимся к устойчивому развитию, чтобы наша зелень оставалась зеленой не только в одном, но и в другом смысле, не придавая чрезмерного значения экологическим аспектам.
				</Text>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				justify-content="flex-start"
				lg-width="100%"
				align-items="flex-start"
				lg-margin="0px 0px 32px 0px"
				margin="0px 0px 0px 0px"
				padding="0px 32px 0px 0px"
				lg-padding="0px 0px 0px 0px"
				lg-justify-content="center"
			>
				<Image
					src="https://zuniqa.com/img/2.jpg"
					object-fit="cover"
					width="100%"
					height="100%"
					border-radius="24px"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					sm-min-height="100vw"
					max-height="380px"
				/>
			</Box>
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 0px 16px 16px"
				justify-content="center"
			>
				<Text margin="0px 0px 40px 0px" color="--greyD3" font="--lead" lg-text-align="center">
				Greenwood Fairways - превзойти все ожидания, один раунд за раз
				</Text>
				<Link
					href="/contacts"
					padding="12px 24px 12px 24px"
					color="--light"
					text-decoration-line="initial"
					font="--lead"
					border-radius="8px"
					margin="0px 16px 0px 0px"
					transition="background-color 0.2s ease-in-out 0s"
					sm-margin="0px 0px 16px 0px"
					sm-text-align="center"
					background="--color-secondary"
					hover-transition="background-color 0.2s ease-in-out 0s"
					hover-background="--color-primary"
				>
					Контакты
				</Link>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});